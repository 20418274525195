import React from "react";
import "./App.css";

function App() {
  return (
    <>
      <div className="title-container">
        <h1 className="roggue-header">
          rog<span className="roggue-letter">g</span>ue
        </h1>
        <h2 className="roggue-subheader">technologies</h2>
      </div>
      <div className="feature-container">
        <p>&#129526;</p>
        <p>Building something cool... eventually.</p>
      </div>
      <div id="footer">
        <p>&copy; Roggue Technologies, LLC</p>
      </div>
    </>
  );
}

export default App;
